var header = $('.header');
var fsHeight = $('.fs').innerHeight();

$(window).scroll(function() {
  var scroll = $(window).scrollTop();

  if (scroll >= fsHeight ) {
    header.addClass("e--bg-dark");
  }
  else{
    header.removeClass("e--bg-dark");
  }
});

$(function () {
	$('.j-popup-modal').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#username',
		modal: true
	});
	$(document).on('click', '.popup-modal-dismiss', function (e) {
		e.preventDefault();
		$.magnificPopup.close();
	});
});